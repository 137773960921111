import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/nodiscrimination/childprotection_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/nodiscrimination/childprotection_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/nodiscrimination/childprotection_card_img3@2x.jpg';
import WedoIco1 from '@/assets/img/nodiscrimination/childprotection_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/nodiscrimination/childprotection_obj1_2@2x.png';
import WedoIco3 from '@/assets/img/nodiscrimination/childprotection_obj1_3@2x.png';
import WedoIco31 from '@/assets/img/nodiscrimination/childprotection_obj2020_1@2x.png';
import WedoIco32 from '@/assets/img/nodiscrimination/childprotection_obj2020_2@2x.png';
import WedoIco33 from '@/assets/img/nodiscrimination/childprotection_obj2020_3@2x.png';
import WedoIco34 from '@/assets/img/nodiscrimination/childprotection_obj2020_4@2x.png';
import WedoIco35 from '@/assets/img/nodiscrimination/childprotection_obj2020_5@2x.png';
import WedoIco4 from '@/assets/img/nodiscrimination/childprotection_obj2_1@2x.png';
import WedoIco5 from '@/assets/img/nodiscrimination/childprotection_obj2_2@2x.png';
import WedoIco6 from '@/assets/img/nodiscrimination/childprotection_obj2_3@2x.png';
import WedoIco7 from '@/assets/img/nodiscrimination/childprotection_obj2_4@2x.png';
import ChildprotectionTagetObj from '@/assets/img/nodiscrimination/childprotection_sec2_obj2@2x.png';
import ChildprotectionTagetObjM from '@/assets/img/nodiscrimination/childprotection_sec2_obj2M@2x.png';
import AccordionComponent, { AccordionItem } from '@/components//Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
  SectionWedo,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `폭력 예방 및 대응 시스템 강화`,
    dec: `유니세프는 각국이 더 많은 어린이 폭력 피해자를 위해 제도, 사회복지, 교육, 보건 분야 등 대응 및 예방 서비스 역량을 강화할 수 있도록 지원합니다. 또한 정부가 어린이 보호 시스템 마련에 더 많은 예산을 지출할 수 있도록 협력합니다. 유니세프는 특히 사례 관리, 사고 모니터링 및 프로그램 모니터링 자료를 통해 어린이 보호를 위한 통합 정보 관리 시스템을 마련하는 것이 어린이 보호 시스템 강화의 중요한 요소로 보고 각국 정부와 협력합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `아동권리 침해 관습 철폐`,
    dec: `조혼과 할례 등 어린이가 기본적으로 누려야 하는 권리를 침해하는 관습을 없애기 위해 지원합니다. 국가행동 계획에 관련 목표를 추가하고 제도적으로 보호할 수 있는 방법을 개발합니다. 조혼 등 성별에 따른 사회적 관습에 영향을 미칠 수 있도록 지역사회와 행동 변화 커뮤니케이션을 펼칩니다. 또한 할례 종식을 위한 사회규범 변화에도 힘씁니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `아동친화적 사법체계 구축`,
    dec: `유니세프는 아동친화적이고 젠더 감수성이 높은 사법제도 접근 방식을 위해 경찰, 판사, 검사 및 변호사와 협력합니다. 또한 피해자의 회복과 치유를 최우선으로 고려하면서 가해자에 대해서도 피해자와의 관계 치유를 통한 사회 복귀와 회복을 목적으로 하는 ‘회복적 정의’ 접근 방식을 지원합니다. 유니세프는 가족 및 지역사회 기반 보호체계를 지원하여 재판을 보류 중인 아동을 구금으로부터 보호하며 필요에 따라 아동에게 전문적인 법률 지원을 제공합니다.`,
    img: CardImg3,
  },
];

const wedo = [  
  {
    id: 1,
    tit: `2019년 주요 성과`,
    col: `col_4`,
    child: [
      {
        id: 1,
        img: WedoIco4,
        desc: [
          {
            dec: `<strong>47개국</strong> 어린이 <strong>2,100만 명</strong>의 출생 등록 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco5,
        desc: [
          {
            dec: `분쟁과 재해를 겪은 어린이 <strong>370만 명</strong>에게 심리 치료 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco6,
        desc: [
          {
            dec: `<strong>76개국</strong> 난민 어린이 <strong>170만 명</strong>에게 보호 서비스 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco7,
        desc: [
          {
            dec: `여성 할례로부터 여자 어린이 <strong>16만 명</strong> 보호`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2020년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>4,720만 명</strong> 어린이 및 보호자에게 지역사회 기반 정신건강 및 심리 치료 서비스 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco32,
        desc: [
          {
            dec: `여자 청소년 약 <strong>600만 명</strong> 조혼 근절 캠페인 참여`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `<strong>84개국</strong> 약 <strong>1,780만 명</strong>에게 성 기반 폭력 대응 및 예방 서비스 지원`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco34,
        desc: [
          {
            dec: `폭력을 경험한 <strong>126개국 420만 명</strong> 어린이 보호 및 사법 서비스 제공`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco35,
        desc: [
          {
            dec: `<strong>126개국 460만 명</strong> 어린이와 여성이 폭력 신고 창구 이용`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2021년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco34,
        desc: [
          {
            dec: `폭력을 경험한 어린이 <strong>440만 명</strong>에게 건강, 사회복지 및 사법 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco33,
        desc: [
          {
            dec: `어린이와 청소년 <strong>840만 명</strong> 및 보호자 <strong>360만 명</strong>에게 정신건강 서비스 및 심리 치료 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco32,
        desc: [
          {
            dec: `지역사회와 협력해 여자 어린이 <strong>760만 명</strong>에게 조혼 예방 및 돌봄 지원`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco35,
        desc: [
          {
            dec: `구금됐던 <strong>84개국</strong> 어린이<br/><strong>4만 5,000명</strong> 해방`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>81개국</strong> 어린이 <strong>38만 4,000명</strong>에게 아동친화적 사법 서비스 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2022년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco34,
        desc: [
          {
            dec: `<strong>109개 국가</strong>에서 폭력을 경험한 어린이 <strong>470만 명</strong>에게 건강, 사회복지 및 사법 서비스 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco33,
        desc: [
          {
            dec: `여자 어린이 <strong>38만 명</strong> 대상 <br />할례 예방 및 할례 피해 치료`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco32,
        desc: [
          {
            dec: `여자 어린이 <strong>1,720만 명</strong> <br />조혼 예방`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco35,
        desc: [
          {
            dec: `<strong>4,900만 명</strong>에게 안전하고 신속한 성폭력 신고 채널 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    tit: `2023년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco34,
        desc: [
          {
            dec: `보호자 <strong>1,180만 명</strong>에게 양육 프로그램 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco33,
        desc: [
          {
            dec: `폭력 피해 어린이 <strong>450만 명</strong>에게 사회복지 및 사법 서비스 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco35,
        desc: [
          {
            dec: `어린이와 보호자 <strong>3,470만 명</strong>에게 정신 건강 및 심리·사회적 서비스 제공`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;

const ChildProtection: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST03'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A006'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="보호"
      description="for every child, protection"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 폭력, 착취, 학대로부터{` `}
                  <br className="small-hide" />
                  보호받을 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프는 정부, 기업, 시민 사회단체 및 지역 사회와 협력하여
                어린이에 대한 모든 형태의 폭력을 예방하고 정신 건강 및 심리 사회
                서비스를 포함한 피해자를 지원합니다. 또한 어린이가 출생부터
                성인이 되기 전까지 중요한 사회보장 서비스를 이용할 수 있도록
                아동 보호 시스템 강화를 지원합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="K0PduKjn3Zc" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionTarget className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="col-header">
              <h2>
                <Tit size="s1">
                  2022-2025 <br />
                  유니세프 중점 사업 목표
                </Tit>
              </h2>
              <ul className="blit">
                <li>
                  5세 미만 어린이 출생 등록률 <strong>증가</strong>
                </li>
                <li>
                  보호자로부터 신체 폭력 및 심리적 학대를 경험하는 어린이{' '}
                  <strong>감소</strong>
                </li>
                <li>
                  구금된 어린이 <strong>감소</strong>
                </li>
              </ul>
            </div>
            <Image
              pcSrc={ChildprotectionTagetObj}
              mobileSrc={ChildprotectionTagetObjM}
            />
          </SectionHeader>
        </Container>
      </SectionTarget>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                모든 어린이의 보호를 위해, <br /> 전 세계 파트너와 함께
                유니세프가 한 일
              </Tit>
            </h2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList
                    itemData={row.child}
                    type={row.col ? row.col : ``}
                  />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A006" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default ChildProtection;
